<template>
  <div class="form" id="forms2">
    <div class="form_con">
      <h2>我的作品</h2>
      <div class="list">
        <ul v-if="list.length>=0">
          <li v-for="(item,index) in list" :key="index" @click="toDetail(item.id)">
            <div class="thumb">
              <div class="thumb_img"><img :src="item.thumb" /></div>
              <div class="del" @click.stop="delpop(item.id)">删除</div>
              <div class="edit" @click.stop="edit(item.id)">编辑</div>
            </div>
            <div class="title">{{item.title}}</div>
          </li>
        </ul>
      </div>
      <el-empty v-if="!list || list.length<=0" description="暂无数据"></el-empty>
     </div>
  </div>
</template>
<script>
export default {
  name: "matchForm",
  data() {
    return {
      events_id:'',
      list:[]
    };
  },
  components:{
  },
  mounted() {
    this.events_id=1;
    this.getList();
    // var odiv = document.getElementById('forms2');
    // let s =this.getDistanceOfLeft(odiv).top;
    // console.log(s);
    // document.body.scrollTop = s;
    // document.documentElement.scrollTop=s;
  },
  methods: {
    getList() {
      this.$api.POST(this.$face.events_myworks, { events_id: this.events_id }, (res) => {
        if(res.code==200){
          this.list=res.data;
          if(this.list instanceof Object ==true && this.list instanceof Array==false){
            this.list=[];
          }
          console.log(this.list);
        }
      });
    },
    edit(id){
       let routeData = this.$router.resolve({
          name: "matchForm",
          query: {
            events_id: 1,
            id: id
          },
        });
        window.open(routeData.href, "_blank");
    },
    delpop(id){
       this.$confirm('此操作将删除该作品, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.del(id);
        }).catch(() => {
               
        });
    },
    del(id) {
      this.$api.POST(this.$face.events_delworks, { id: id }, (res) => {
        if(res.code==200){

         this.$utile.prompt("success", "删除成功");
         this.getList();
        }
      });
    },
    toDetail(id){
      let routeData = this.$router.resolve({
          name: "matchDetail",
          query: {
            id: id
          },
        });
        window.open(routeData.href, "_blank");
    },
    getDistanceOfLeft(obj) {
      var left = 0;
      var top = 0;
      while (obj) {
        left += obj.offsetLeft;
        top += obj.offsetTop;
        obj = obj.offsetParent;
      }
      return {
        left: left,
        top: top,
      };
    }
  },
};
</script>

<style scoped>
.form {
  background: #0000fe;
  overflow: hidden;
}
.form_con {
  width: 1255px;
  background: #fff;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 35px 45px;
  margin: 105px auto;
  min-height: 800px;
}
.form_con h2 {
  font-size: 38px;
  height: 80px;
  line-height: 80px;
  border-bottom: 1px #bfbfbf solid;
}
.form_con {
  overflow: hidden;
}
.list{ padding-top: 30px;}
.list li{ width: 365px; height: 340px; float: left; margin:0 11px 22px; overflow: hidden;cursor: pointer;}
.list li .thumb{ width: 365px; height: 270px; position: relative; background: #f2f2f2; border-radius: 6px; position: relative; overflow: hidden; border-radius: 10px;}
.list li .thumb .thumb_img{position: absolute; top: 50%; transform: translateY(-50%); width: 100%;}
.list li .thumb .thumb_img img{ width: 100%; transition: 0.3s;}
.list li .title{ font-size: 20px; margin-top: 25px; color: #333333;}
.list li .thumb .del,.list li .thumb .edit{ width: 60px; height: 32px; font-size: 16px; color: #fff; text-align: center; line-height: 32px; background: rgba(0,0,0,0.5); border-radius: 6px; display: none; cursor: pointer;}
.list li .thumb .del{ position: absolute; left: 12px; top: 12px;}
.list li .thumb .edit{ position: absolute; right: 12px; top: 12px;}
.list li:hover .edit,.list li:hover .del{ display: block;}
.list li:hover .thumb .thumb_img img{transform:scale(1.05);}
</style>